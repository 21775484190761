import { orbitControl, camera } from './camera';
import { renderer } from './renderer';
import { scene, world } from './scene';
import { objects } from './Utils/objects';
import * as THREE from 'three';

objects();

const clock = new THREE.Clock();
let oldElapsedTime = 0;

const Experience = () => {
    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - oldElapsedTime;
    oldElapsedTime = elapsedTime;

    orbitControl.update();

    world.step(1 / 60, deltaTime, 3);

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    requestAnimationFrame(Experience);
    renderer.render(scene, camera);
};

export default Experience;