import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { scene } from '../scene';
import { gsap } from 'gsap';

/**
 * Overlay
 */
const overlayGeometry = new THREE.PlaneBufferGeometry(2, 2, 1, 1);
const overlayMaterial = new THREE.ShaderMaterial({
    // wireframe: true,
    transparent: true,
    uniforms:
    {
        uAlpha: { value: 1 }
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;
        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
});
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial);
scene.add(overlay);

const loadingBarElement = document.querySelector('.loading-bar')
const loadingManager = new THREE.LoadingManager(
    // Loaded
    () => {
        // Wait a little
        window.setTimeout(() => {
            // Animate overlay
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 });

            // Update loadingBarElement
            loadingBarElement.classList.add('ended');
            loadingBarElement.style.transform = '';
        }, 500);
    },

    // Progress
    (itemUrl, itemsLoaded, itemsTotal) => {
        // Calculate the progress and update the loadingBarElement
        const progressRatio = itemsLoaded / itemsTotal;
        loadingBarElement.style.transform = `scaleX(${progressRatio})`;
    }
);

const draco_loader = new DRACOLoader(loadingManager);
draco_loader.setPath('draco/');

const gltf_loader = new GLTFLoader(loadingManager);
gltf_loader.setDRACOLoader(draco_loader);

const texture_loader = new THREE.TextureLoader(loadingManager);

const model_size = 4.5;

const updateAllMaterials = () => {
    scene.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            child.material.needsUpdate = true;
            child.castShadow = true;
            child.receiveShadow = true;
        }
    })
};

/**
 * THREE OBJECT
*/
const objects = () => {
    const pole_material = new THREE.MeshBasicMaterial({ color: 0xffffe5 });
    const baked_texture = texture_loader.load('baked.jpg');
    baked_texture.flipY = false;
    baked_texture.encoding = THREE.sRGBEncoding;

    gltf_loader.load(
        '/merged_portal.glb',
        gltf => {
            gltf.scene.rotation.set(0, -Math.PI / 2, 0);
            gltf.scene.scale.set(model_size, model_size, model_size);
            gltf.scene.traverse(child => child.material = new THREE.MeshBasicMaterial({ map: baked_texture }));
            gltf.scene.receiveShadow = true;
            scene.add(gltf.scene);

            gltf.scene.children.map(child => {
                switch (child.name) {
                    case 'Cube041':
                    case 'Cube042':
                        child.material = pole_material;
                        break;

                    case 'Circle':
                        child.material = new THREE.MeshBasicMaterial({ color: 0xffffff });
                        break;

                    default:
                        break;
                };
            });

            updateAllMaterials();
        }
    );
};

export {
    objects
};