import * as THREE from 'three';
import * as CANNON from 'cannon-es';
import datgui from 'dat.gui';

const scene = new THREE.Scene();
const world = new CANNON.World();

var debugObject = {
    clearColor: '#070404'
};
const gui = new datgui.GUI();

// const cube_texture_loader = new THREE.CubeTextureLoader();

// const environmentMap = cube_texture_loader.load([]);
// environmentMap.encoding = THREE.sRGBEncoding;

// scene.background = environmentMap;
// scene.environment = environmentMap;

scene.background = new THREE.Color(debugObject.clearColor);
gui
    .addColor(debugObject, 'clearColor')
    .onChange(() => scene.background = new THREE.Color(debugObject.clearColor));

world.broadphase = new CANNON.SAPBroadphase(world);
world.allowSleep = true;
world.gravity.set(0, - 9.82, 0);

// Default material
const defaultMaterial = new CANNON.Material('default');
const defaultContactMaterial = new CANNON.ContactMaterial(
    defaultMaterial,
    defaultMaterial,
    {
        friction: 0.1,
        restitution: 0.7
    }
);
world.defaultContactMaterial = defaultContactMaterial;

export {
    scene,
    defaultMaterial,
    world
};