import * as THREE from 'three';
import { scene } from './scene';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight);
camera.position.set(4, 10, 13);
scene.add(camera);

const orbitControl = new OrbitControls(camera, document.querySelector('.experience'))
orbitControl.enableDamping = true;

export {
    camera,
    orbitControl
};